import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import React from "react";
import Login from "./app/login/index";
import Home from "./app/home/index";
import Information from "./app/information/index";
import Coupon from "./app/coupon/index";
import Poster from "./app/poster/index";
import CouponQr from "./app/couponQr/index";
import ExchangeCoupon from "./app/exchangeCoupon/index";
import Wxscan from "./app/wxscan/index";
import Wxadd from "./app/wxAdd/index";
import WxEdit from "./app/wxEdit/index";
import Show from "./app/show/index";
import PrizeMay from "./app/PrizeMay/index";
import ParkWelcome from "./app/Park/Welcome";
import ParkAdd from "./app/Park/Add";
import ParkCoupon from "./app/Park/Coupon";
import ParkCouponResult from "./app/Park/CouponResult";
import ParkAddResult from "./app/Park/AddResult";
import ParkEdit from "./app/Park/Edit";
import ParkShop from "./app/Park/Shop";
import ParkRecord from "./app/Park/Record";
import ParkRecordDetail from "./app/Park/RecordDetail";
import ParkPayResult from "./app/Park/PayResult";

export default () => {
  return (
    <Router>
      <div id="router">
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/information" component={Information} />
        <Route exact path="/coupon" component={Coupon} />
        <Route exact path="/exchangeCoupon" component={ExchangeCoupon} />
        <Route exact path="/poster" component={Poster} />
        <Route exact path="/couponQr/:id" component={CouponQr} />
        <Route exact path="/wxscan" component={Wxscan} />
        <Route exact path="/wxadd" component={Wxadd} />
        <Route exact path="/wxedit" component={WxEdit} />
        <Route exact path="/show" component={Show} />
        <Route exact path="/prizemay" component={PrizeMay} />
        <PrivateRoute exact path="/park" component={ParkWelcome} />
        <Route exact path="/park/add" component={ParkAdd} />
        <Route exact path="/park/coupon" component={ParkCoupon} />
        <Route exact path="/park/coupon/result" component={ParkCouponResult} />
        <Route exact path="/park/add/result" component={ParkAddResult} />
        <Route exact path="/park/edit/:id" component={ParkEdit} />
        <Route exact path="/park/shop" component={ParkShop} />
        <Route exact path="/park/record" component={ParkRecord} />
        <Route exact path="/park/payother" component={ParkAdd} />
        <Route exact path="/park/pay/result" component={ParkPayResult} />

        <Route
          exact 
          path="/park/record/detail/:id"
          component={ParkRecordDetail}
        />
      </div>
    </Router>
  );
};
