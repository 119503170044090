import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './store';
import './index.less';
import { isInMiniProgram, getUrlArg } from './util/index';
import { Toast } from 'antd-mobile';
import __URL__ from "../config/index";
import VConsole from 'vconsole';

const domainVersion = getUrlArg('domainVersion');
const isShowVConsole = getUrlArg('console');
if(isShowVConsole){
    new VConsole();
}
// 如果是小程序环境，并且跟了小程序版本号，就缓存下来, 根据版本号获取域名映射数据
if(window.location.pathname.includes("/park") && isInMiniProgram() && domainVersion){
	const ENV = process.env.NODE_ENV;
	const domain = __URL__[ENV]
	let body = JSON.stringify({  
		"domainVersion": domainVersion,
		"domainPlatformType": 0,
	})
	Toast.loading('加载中...', 3);
	fetch(`${domain.himikDomain}/xl-contentApi/domainChange/invoker.do`, {
		method: 'POST',
		headers: {
		'Content-Type': 'application/json',
		domainVersion: domainVersion,
		domainPlatformType: 0
		},
		body: body
	})
		.then(response => response.json())
		.then(data => {
		if (data.code === 0) {
			__URL__.setParkDomainConfig(data.data);
		}
		Toast.hide();
		ReactDOM.render(
			<Provider store={store}>
				<Routes />
			</Provider>,
			document.getElementById('app')
		);
		})
		.catch(error => {
			Toast.hide();
			ReactDOM.render(
				<Provider store={store}>
					<Routes />
				</Provider>,
				document.getElementById('app')
			);
		});
}else {
	ReactDOM.render(
		<Provider store={store}>
			<Routes />
		</Provider>,
		document.getElementById('app')
	);
}
