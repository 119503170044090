import React from 'react';
import { connect } from 'react-redux';
import { parkCurShop as parkCurShopStorage } from '@/util/storageManager';
import couponSucIcon from '../images/coupon-suc-icon.png';
import qs from "qs";
import moment from "moment";

import './index.less';
import classNames from '@/util/classNames';
const prefix = 'park-coupon-result';
const cx = classNames(prefix);

class COUPON extends React.Component {
    constructor(props) {
        super(props);
        const { search } = this.props.location;
		const obj = qs.parse(search);
		const carNumber = obj.carNumber || obj["?carNumber"];
		const hour = obj.hour || obj["hour"];
        this.state = {
            carNum: carNumber,
            hour: hour,
        };
    }

    componentWillMount() {
    }

    goToPark = () => {
        const { history } = this.props
        history.replace('/park')
    }
    

    render() {
        const { carNum, hour } = this.state;
        const {
            location: { state: { empty } = {} },
        } = this.props;
        const { shopName = '' } = parkCurShopStorage.getItem() || {};

        return (
            <div className={prefix}>
                <div className={cx('top')}>
                    <img src={couponSucIcon} width={106}/>
                </div>
                <div className={cx('info-box')}>
                    <div className={cx('info-car-num')}>{carNum}</div>
                    <div className={cx('info-hour')}>
                        优惠停车时长：
                        <span style={{color: '#F60'}}>{hour}小时</span>
                    </div>
                    <div className={cx('info-time')}>
                        有效期至：
                        <span style={{color: '#4E5969'}}>{ moment().format("YYYY-MM-DD 23:59") }</span>
                    </div>
                </div>
                <div
                    className={cx('opt-box')}
                    onClick={this.goToPark}
                >
                    去使用
                </div>
            </div>
        );
    }
}

const key = 'parkNew';
export default connect(
    (state) => {
        return {
            parkNew: state[key],
            carFeeLoading: state.loading.effects[key].carFeeRecord,
            carBindLoading: state.loading.effects[key].carBind,
            carFeePreViewLoading: state.loading.effects[key].carFeePreView,
            ktCarShopListLoading: state.loading.effects[key].getKtCarShopList,
        };
    },
    (dispatch) => {
        return {
            handleCarBind: dispatch[key].carBind,
            getCarFeeRecord: dispatch[key].carFeeRecord,
            carFeePreView: dispatch[key].carFeePreView,
            getKtCarShopList: dispatch[key].getKtCarShopList,
        };
    }
)(COUPON);
