import React from 'react';
import { connect } from 'react-redux';
import { Toast } from 'antd-mobile';
import { Head, AppBar, BgBox, CarInputKeyboard, NewModalCustomize } from '../components';
import { parkCurShop as parkCurShopStorage } from '@/util/storageManager';
import { isInMiniProgram } from '@/util/index';
import qs from "qs";
import './index.less';
import classNames from '@/util/classNames';
const prefix = 'park-add';
const cx = classNames(prefix);

let pageType = 'add';
const INPUT_DESCRIBE = '请绑定真实有效的车牌号';
const TITLE = { add: '绑定车牌', payother: '停车服务' };
const BUTTON_TEXT = { add: '绑定车牌', payother: '查缴停车费' };

const PAY_URL = ({ parkId, carNumber }) => {
    const result = {
        9104: `http://qqb.welinkpark.com/cw/g.html?b=t&s=${parkId}-${carNumber}`, // 上海金桥店
        1004: `https://cloud.keytop.cn/stcfront/Parking/Query?lpn=${carNumber}`, // 上海龙阳店
        1801: `http://app.gateares.com:19983/cc-pay/index.html?commId=1.000.729&plateNO=${encodeURIComponent(
            carNumber
        )}`, // 广州天河店正式地址
        kt: `https://cloud.keytop.cn/stcfront/Parking/Query?lotId=${parkId}&lpn=${carNumber}`, // 自营店
        typeZero: `http://wx.ymiot.net/SCPay/?carNo=${carNumber}`,
    };
    return result;
};

class ADD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carNumber: '',
            sureErrorPayModal: false,
            preViewPayModal: false,
            carFeePreViewData: {},
        };
        this.inputRef = React.createRef();
    }

    componentWillMount() {
        const {
            location: { pathname },
            getKtCarShopList,
        } = this.props;
        pageType = pathname.split('/')[2];
        getKtCarShopList();
    }

    onChange = (carNumber) => {
        this.setState({ carNumber });
    };

    // payDisabled: false支付  true不用支付  isTypeZero:支付链接类型  sureErrorContent:提示信息
    handlePay = async () => {
        try {
            // 获取到停车记录 验证车是否进场
            const { carNumber } = this.state;
            const { shopNo } = parkCurShopStorage.getItem() || {};
            const {
                carFeePreView,
                parkNew: { ktCarShopListSelf },
            } = this.props;

            if (ktCarShopListSelf.includes(shopNo)) {
                // 如果是自营+科拓  支付前弹框显示停车信息
                const { data = {} } = await this.props.carFeePreView({ carNumber });
                this.setState({
                    preViewPayModal: true,
                    carFeePreViewData: data,
                });
            } else {
                // 除了自营+科拓  直接跳转到第三方支付链接
                if(isInMiniProgram()){
                    this.setState({
                        sureErrorPayModal: true,
                        sureErrorContent: '小程序暂不支持第三方停车场支付，请前往App内操作',
                    });
                    return;
                }
                // 除自营+科拓  直接跳转到第三方支付链接
                this.goThirdpay();
            }
        } catch (error) {
            // 无车辆记录时  提示错误信息
            const { message } = error;
            this.setState({
                sureErrorPayModal: true,
                sureErrorContent: message,
            });
        }
    };

    goThirdpay = async () => {
        try {
            const {
                getCarFeeRecord,
                parkNew: { ktCarShopListAll },
            } = this.props;
            const { parkId, shopNo } = parkCurShopStorage.getItem() || {};
            const { carNumber } = this.state;

            let isTypeZero = false;
            if (shopNo !== '1801' && ktCarShopListAll.includes(shopNo)) {
                const json = await getCarFeeRecord({ shopNo, carNumber });
                const { result: { data: info = {} } = {} } = json;
                isTypeZero = info && info.type == 0;
            }

            const isMiniprogram = window.__wxjs_environment === 'miniprogram';
            if (isMiniprogram && ktCarShopListAll.includes(shopNo)) {
                this.handleMiniProgram(parkId, carNumber);
                return;
            }

            const payKey = isTypeZero
                ? 'typeZero'
                : ktCarShopListAll.includes(shopNo)
                ? 'kt'
                : shopNo;
            window.location.href = PAY_URL({
                parkId,
                carNumber,
            })[payKey];
        } catch (error) {
            // 无车辆记录时  提示错误信息
            const { message } = error;
            this.setState({
                sureErrorPayModal: true,
                sureErrorContent: message,
            });
        }
    };

    handleMiniProgram = (parkId, carNumber) => {
        wx.miniProgram.postMessage({
            data: {
                type: 'parkJumpThird',
                appId: 'wx54e676273869baa6',
                path: `pages/index/index?lotId=${parkId}&lpn=${carNumber}`,
            },
        });
        wx.miniProgram.navigateBack();
    };

    onClick = async () => {
        const { search } = this.props.location;
		const obj = qs.parse(search);
		const pathType = obj.type || obj['?type'] || obj['type'] || "add";
        const { carNumber } = this.state;
        const { handleCarBind } = this.props;
        // 验证填写的车牌号
        try {
            if (!carNumber.trim()) {
                throw new Error('请填写车牌号');
            }
            if (carNumber.trim().length < 7) {
                throw new Error('请填写正确的车牌号');
            }
        } catch (error) {
            Toast.fail(error.message);
            return;
        }
        if (pageType === 'add') {
            // 新增
            const { code } = await handleCarBind({ carNumber: carNumber.trim() });
            if (code === 0) {
                Toast.success('绑定成功', 1, () => {
                    if(pathType === 'coupon'){
                        this.props.history.replace('/park/coupon');
                        return;
                    }
                    this.props.history.push('/park');
                });
            }
        } else if (pageType === 'payother') {
            // 帮其他车辆缴费
            this.handlePay();
        }
    };

    render() {
        const {
            carNumber,
            sureErrorPayModal,
            sureErrorContent,
            preViewPayModal,
            carFeePreViewData,
        } = this.state;
        const {
            location: { search, state: { empty } = {} },
            carBindLoading,
            carFeeLoading,
            carFeePreViewLoading,
            ktCarShopListLoading,
        } = this.props;
		const obj = qs.parse(search);
		const pathType = obj.type || obj['?type'] || obj['type'] || "add";
        const hideAppBar = empty === true || pathType === 'coupon';
        const { shopName = '' } = parkCurShopStorage.getItem() || {};

        return (
            <div className={prefix}>
                {!hideAppBar && <AppBar title={TITLE[pageType]} toLink link='/park' />}
                <Head
                    title={`${shopName}`}
                    iconType='down'
                    handleProps={{
                        onClick: () => {
                            this.props.history.push(`/park/shop?redirect=${pageType}`);
                        },
                    }}
                />
                <BgBox className={cx('box')}>
                    <CarInputKeyboard
                        title={pageType === 'payother' ? '车牌' : ''}
                        btnText={BUTTON_TEXT[pageType]}
                        btnProps={{
                            disabled:
                                carBindLoading ||
                                carFeeLoading ||
                                carFeePreViewLoading ||
                                ktCarShopListLoading,
                            onClick: this.onClick,
						    style:{ opacity: carNumber.length < 7 ? '0.5': '1' }
                        }}
                        onChange={this.onChange}
                        inputProps={{ value: carNumber, placeholder: INPUT_DESCRIBE }}
                    />
                </BgBox>
                <NewModalCustomize
                    visible={sureErrorPayModal}
                    content={
                        <p>
                            <span>{sureErrorContent}</span>
                        </p>
                    }
                    footer={[
                        {
                            text: '知道了',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ sureErrorPayModal: false });
                            },
                        },
                    ]}
                />

                {/* 查缴停车费之前的预览 */}
                <NewModalCustomize
                    visible={preViewPayModal}
                    transparent
                    title={`【${this.state.carNumber}】`}
                    content={
                        <div className='preViewPayContent'>
                            <p>
                                <span>入场时间</span>
                                <span>{carFeePreViewData.entryTime}</span>
                            </p>
                            <p>
                                <span>停车时长</span>
                                <span>{carFeePreViewData.parkTime}</span>
                            </p>
                            <p>
                                <span>优惠时长</span>
                                <span>{carFeePreViewData.deductTime}</span>
                            </p>
                        </div>
                    }
                    footer={[
                        {
                            text: '取消',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ preViewPayModal: false });
                            },
                        },
                        {
                            text: '确认离场,去支付',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ preViewPayModal: false });
                                this.goThirdpay();
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

const key = 'parkNew';
export default connect(
    (state) => {
        return {
            parkNew: state[key],
            carFeeLoading: state.loading.effects[key].carFeeRecord,
            carBindLoading: state.loading.effects[key].carBind,
            carFeePreViewLoading: state.loading.effects[key].carFeePreView,
            ktCarShopListLoading: state.loading.effects[key].getKtCarShopList,
        };
    },
    (dispatch) => {
        return {
            handleCarBind: dispatch[key].carBind,
            getCarFeeRecord: dispatch[key].carFeeRecord,
            carFeePreView: dispatch[key].carFeePreView,
            getKtCarShopList: dispatch[key].getKtCarShopList,
        };
    }
)(ADD);
